import { createContext, useContext } from "react";
import GameOfLife1 from "../assets/Pic/GameOfLife/GOL1.png";
import GameOfLife2 from "../assets/Pic/GameOfLife/GOL2.png";
import GameOfLife3 from "../assets/Pic/GameOfLife/GOL3.png";

//CU

interface GameOfLifeContextInterface {
  GameOfLifeImage: string[];
}

const GameOfLifeContext = createContext({
  GameOfLifeImage: [GameOfLife1, GameOfLife2, GameOfLife3],
});

export const useGameOfLife = (): GameOfLifeContextInterface => {
  return useContext(GameOfLifeContext);
};
