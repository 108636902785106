import React, {useEffect, useState} from "react";
import {Button, Typography} from "@mui/material";
import linkedInLogo from "../assets/48.png";
import githubLogo from "../assets/Github.png";
import "../StyleSheets/FooterBar.css";
import axios from "axios";
import config from "../serverModeConfig";

function FooterBar() {
  let URL: String = "TEST";
  const apiCallToken = process.env.REACT_APP_TOKEN;
  let username: string = "";
  if (process.env.REACT_APP_USERNAME != undefined) {
    username = process.env.REACT_APP_USERNAME;
  }
  let password: string = "";
  if (process.env.REACT_APP_PASSWORD != undefined) {
    password = process.env.REACT_APP_PASSWORD;
  }
  // Determines if we are in Dev Or Prod Mode for Development Purpose
  switch (
    config.mode //Analytic Data
  ) {
    case undefined:
      console.log("PROD or Dev URl Is Not Set");
      break;
    case "dev":
      if (process.env.REACT_APP_DEV_URL != undefined)
        URL = process.env.REACT_APP_DEV_URL;
      break;
    case "prod":
      if (process.env.REACT_APP_PROD_URL != undefined)
        URL = process.env.REACT_APP_PROD_URL;
      break;
    default:
      break;
  }
  const [visitorCount, setVisitorCount] = useState<number>(0);

  const IncrementVisitorCount = async () => {
    // Check if the session cookie exists
    const isCountIncremented = document.cookie.includes(
      "countIncremented=true"
    );
    //This Actually Increments the Count
    if (!isCountIncremented) {
      axios
        .get(URL + "/Count", {
          auth: {
            username: username,
            password: password,
          },
          params: {
            warzone: apiCallToken,
          },
        })
        .then((response) => {
          // Handle the response data
          setVisitorCount(response.data.count);
          // Set the session cookie to indicate the count has been incremented
          document.cookie = "countIncremented=true";
        })
        .catch((error) => {
          // Handle the error
          console.error(error);
        });
    }
    //This Returns Just The Current Count
    else {
      axios
        .get(URL + "/GetCurrentCount", {
          auth: {
            username: username,
            password: password,
          },
          params: {
            warzone: apiCallToken,
          },
        })
        .then((response) => {
          // Handle the response data
          setVisitorCount(response.data.count);
          // Set the session cookie to indicate the count has been incremented
          document.cookie = "countIncremented=true";
        })
        .catch((error) => {
          // Handle the error
          console.error(error);
        });
    }
  };

  useEffect(() => {
    IncrementVisitorCount().then((r) => {});
  }, []);

  return (
    <div className={"footBar"} style={{ zIndex: 2 }}>
      <Button
        href={"https://www.linkedin.com/in/phillip-yates-63803614a/"}
        data-testid="linkinbutton"
        target={"_blank"}
      >
        <img src={linkedInLogo} alt="LinkInButton" />
      </Button>
      <Button
        href={"https://github.com/Phillip8829"}
        target={"_blank"}
        data-testid="githubbutton"
      >
        <img src={githubLogo} alt="GitHubButton" />
      </Button>
      <Typography sx={{ color: "white" }}>
        Visitor Count: {visitorCount}
        <br />
        Ver: 1.0.0
      </Typography>
    </div>
  );
}

export default FooterBar;
