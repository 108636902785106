import React from "react";
import "../StyleSheets/HomePage.css";
import ParticlesComponent from "../Component/ParticlesComponent";
function HomePage() {

    return (
    <>
        <div className="home">
            <h1>Phillip Yates</h1>
            <h4>Portfolio</h4>
        </div>
      </>
    );
}

export default HomePage;
