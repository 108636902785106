import { createContext, useContext } from "react";
import FPSDungeon1 from "../assets/Pic/FPSDungeon/1.png";
import FPSDungeon2 from "../assets/Pic/FPSDungeon/2.png";
import FPSDungeon3 from "../assets/Pic/FPSDungeon/3.png";
import FPSDungeon4 from "../assets/Pic/FPSDungeon/4.png";
import FPSDungeon5 from "../assets/Pic/FPSDungeon/5.png";
import FPSDungeon6 from "../assets/Pic/FPSDungeon/6.png";
import FPSDungeon7 from "../assets/Pic/FPSDungeon/7.png";
import FPSDungeon8 from "../assets/Pic/FPSDungeon/8.png";
import FPSDungeon9 from "../assets/Pic/FPSDungeon/8.png";

//CPU

interface FPSDungeonContextInterface {
  FPSDungeonImage: string[];
}

const FPSDungeonContext = createContext<FPSDungeonContextInterface>({
  FPSDungeonImage: [
    FPSDungeon1,
    FPSDungeon2,
    FPSDungeon3,
    FPSDungeon4,
    FPSDungeon5,
    FPSDungeon6,
    FPSDungeon7,
    FPSDungeon8,
    FPSDungeon9,
  ],
});
export const useFPSDungeon = (): FPSDungeonContextInterface => {
  return useContext(FPSDungeonContext);
};
